.order-detail-col-heading, .order-detail-col-val{
    padding: 0;
}

.order-detail-col-heading{
    max-width:150px;
}

.order-detail-container{
    padding: 0 !important;
    margin:0 !important;
}

.order-detail-row{
    padding: 5px 0 5px 0;
    margin-left: 0px;
    margin-right: 0px;
}

.order-summary-info-card{}

.order-summary-info-card .summary-vendor-logo{
    height:75px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}

.google-map>div{
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.quantity-badge{
    font-size:0.9rem;
    background-color: #868e96;
}

.order-items-card .list-group-item{
    padding: 0.5rem 1.25rem;
}

.order-items-card .row{
    padding: 0 0 0 0;
}

.order-items-card .quant-col{
    padding: 0;
    min-width: 40px;
    padding-right:10px;
}

.order-items-card .item-name-col{
    padding-left: 0;
}

.order-items-card .sub-item-entry{
    padding-left:2.2rem !important;
}

.payout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payout-event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.event-description {
    display: flex;
    align-items: center;
}

.event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
}

.bg-grey {
    background-color: #f3f3f3;
}

.border-top-grey {
    border-top: 1px solid #f3f3f3;
}

.tree,
.tree ul {
    margin:0 0 0 1em; /* indentation */
    padding:0;
    list-style:none;
    color: grey;
    position:relative;
}

.tree ul {margin-left:.5em}

.tree:before,
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px dotted;
}

.tree li {
    margin:0;
    padding:0 0 0 1.5em; /* indentation + .5em */
    line-height:2em; /* default list item's `line-height` */
    font-size:0.875em;
    position:relative;
}

.tree li:before {
    content:"";
    display:block;
    width:10px; /* same with indentation */
    height:0;
    border-top:1px dotted;
    margin-top:-1px; /* border top width */
    position:absolute;
    top:1em; /* (line-height/2) */
    left:0;
}

.tree li:last-child:before {
    background:#fff; /* same with body background */
    height:auto;
    top:1em; /* (line-height/2) */
    bottom:0;
}

.tree li:after {
    position: absolute;
    left: 9px;
    top: -1px;
}

.tree:after {
    position: absolute;
    left: -3px;
    top: -8px;
}

@media (max-width: 767.98px) {
    .order-detail-col-heading{
        max-width: 100%;
    }
    
    .order-detail-container{
        padding:0;
    }

    .order-detail-col-val{
        text-align: right;
    }
    
    .order-items-card .list-group-item{
        padding: 0.5rem 0.1rem 0.5rem 0.1rem;
    }

    .order-summary-info-card .summary-vendor-logo{
        height:30px;
    }
}
